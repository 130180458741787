import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getSandyBalance, hasPAWZ } from '../helpers/sandy';
import { useQuery } from '@tanstack/react-query';

export const useGetSandyBalance = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const fetchBalance = async () => {
    if (!publicKey) {
      return null;
    }

    try {
      const balance = await getSandyBalance(connection, publicKey);
      const pawzEnabled = await hasPAWZ(publicKey.toString());
      return { balance, pawzEnabled };
    } catch (error) {
      console.error('Error fetching SANDY balance:', error);
      return null;
    }
  };

  const { data: balance } = useQuery({
    queryKey: ['sandyBalance', publicKey?.toBase58()],
    queryFn: fetchBalance,
    enabled: !!publicKey,
    refetchInterval: 30000, // Refetch every 30 seconds
  });

  return balance;
};
