import React from 'react';
import { toPrecision } from '../helpers/number';
import { useWallet } from '@solana/wallet-adapter-react';
import { useGetSandyBalance } from '../hooks/useGetSandyBalance';

export default function SandyBalance() {
  const { publicKey } = useWallet();
  const balance = useGetSandyBalance();

  if (!publicKey) return null;

  return (
    <div className="text-sandy font-semibold">
      {toPrecision(balance?.balance ?? 0, 4)} $SANDY
      {balance?.pawzEnabled ? (
        <div className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
          PAWZ Enabled
        </div>
      ) : null}
    </div>
  );
}
