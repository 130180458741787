'use client';

import { useWalletMultiButton } from '@solana/wallet-adapter-base-ui';
import { Avatar } from '../components/avatar';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../components/dropdown';
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../components/navbar';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../components/sidebar';
import { SidebarLayout } from '../components/sidebar-layout';
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from '@heroicons/react/16/solid';
import {
  HomeIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { Button } from '../components/button';
import Address from '../components/address';
import SandyBalance from '../components/sandyBalance';
import { Heading } from '../components/heading';

const events = [
  {
    id: 1,
    name: '$BILLY',
    price: 0.032,
    change: -0.03,
    url: '/events/1',
  },
  {
    id: 2,
    name: '$BABYSANDY',
    price: 0.12,
    change: 0.17,
    url: '/events/2',
  },
];

function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const { setVisible: setModalVisible } = useWalletModal();
  const { onDisconnect, publicKey, walletIcon } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(true);
    },
  });

  if (!publicKey) {
    return null;
  }

  return (
    <DropdownMenu className="min-w-64" anchor={anchor}>
      {anchor === 'bottom end' ? (
        <DropdownItem>
          <div>
            <img src={walletIcon} className="w-4 h-4 rounded-full" alt="" />
          </div>
          <div className="">
            <span className="min-w-0">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                <Address link={false} address={publicKey.toBase58()} />
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                <SandyBalance />
              </span>
            </span>
          </div>
        </DropdownItem>
      ) : null}
      {anchor === 'bottom end' ? <DropdownDivider /> : null}
      <DropdownItem href="#">
        <UserCircleIcon />
        <DropdownLabel>My account</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem href="#">
        <ShieldCheckIcon />
        <DropdownLabel>Privacy policy</DropdownLabel>
      </DropdownItem>
      <DropdownItem href="#">
        <LightBulbIcon />
        <DropdownLabel>Share feedback</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem onClick={onDisconnect}>
        <ArrowRightStartOnRectangleIcon />
        <DropdownLabel>Disconnect</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  );
}

function WalletConnectionDropdown({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const { setVisible: setModalVisible } = useWalletModal();
  const { publicKey, walletIcon } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(true);
    },
  });

  if (!publicKey) {
    return (
      <Button color="purple" onClick={() => setModalVisible(true)}>
        Connect <span className="hidden sm:flex">Wallet</span>
      </Button>
    );
  }

  return (
    <Dropdown>
      {anchor === 'bottom end' ? (
        <DropdownButton as={NavbarItem}>
          <img src={walletIcon} className="w-8 h-8 rounded-full" alt="" />
          <Address link={false} address={publicKey.toBase58()} />
        </DropdownButton>
      ) : (
        <DropdownButton as={SidebarItem}>
          <span className="flex min-w-0 items-center gap-3">
            <img src={walletIcon} className="w-10 h-10 rounded-full" alt="" />
            <span className="min-w-0">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                <Address link={false} address={publicKey.toBase58()} />
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                <SandyBalance />
              </span>
            </span>
          </span>
          <ChevronUpIcon />
        </DropdownButton>
      )}
      <AccountDropdownMenu anchor={anchor} />
    </Dropdown>
  );
}

export function ApplicationLayout({ children, path }: { children: React.ReactNode; path: string }) {
  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <WalletConnectionDropdown anchor="bottom end" />
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <SidebarItem>
                <Avatar src="/images/logo.png" />
                <Heading>Sandy Launchpad</Heading>
              </SidebarItem>
              {/* <DropdownButton as={SidebarItem}>
                <Avatar src="/images/logo.png" />
                <SidebarLabel>Catalyst</SidebarLabel>
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <Avatar slot="icon" src="/images/logo.png" />
                  <DropdownLabel>Catalyst</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="#">
                  <Avatar slot="icon" initials="BE" className="bg-purple-500 text-white" />
                  <DropdownLabel>Big Events</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <PlusIcon />
                  <DropdownLabel>New team&hellip;</DropdownLabel>
                </DropdownItem>
              </DropdownMenu> */}
            </Dropdown>
          </SidebarHeader>

          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/" current={path === '/'}>
                <HomeIcon />
                <SidebarLabel>Tokens</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/launch" current={path.startsWith('/launch')}>
                <RocketLaunchIcon />
                <SidebarLabel>Launch</SidebarLabel>
              </SidebarItem>
            </SidebarSection>

            <SidebarSection>
              <SidebarHeading>Your favorites (spinner to update prices)</SidebarHeading>
              {events.map((event) => (
                <SidebarItem key={event.id} href={event.url} className="w-full">
                  <div className="flex justify-between items-center w-full">
                    <span>{event.name}</span>
                    <div className="flex gap-2 items-end">
                      <span className="text-right">${event.price.toFixed(3)}</span>
                      <span
                        className={`text-right ${
                          event.change >= 0 ? 'text-green-500' : 'text-red-500'
                        }`}
                      >
                        {event.change >= 0 ? '+' : ''}
                        {event.change.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </SidebarItem>
              ))}
            </SidebarSection>

            <SidebarSpacer />

            <SidebarSection>
              <SidebarItem href="#">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="#">
                <SparklesIcon />
                <SidebarLabel>Changelog</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>

          <SidebarFooter className="max-lg:hidden">
            <WalletConnectionDropdown anchor="top start" />
          </SidebarFooter>
        </Sidebar>
      }
    >
      {children}
    </SidebarLayout>
  );
}
