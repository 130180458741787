import { getAssociatedTokenAddressSync, TOKEN_2022_PROGRAM_ID } from '@solana/spl-token';
import { Connection, PublicKey } from '@solana/web3.js';

export const SANDY_MINT = '6hBry7w55A3NXDAXxjKqrx3oXfAatNkAJUSGt66ZRsZk';

export const getSandyBalance = async (
  connection: Connection,
  publicKey: PublicKey
): Promise<number | null> => {
  try {
    const ata = getAssociatedTokenAddressSync(
      new PublicKey(SANDY_MINT),
      publicKey,
      false,
      TOKEN_2022_PROGRAM_ID
    );
    const accountInfo = await connection.getTokenAccountBalance(ata);
    return accountInfo.value.uiAmount;
  } catch (error) {
    console.error('Error fetching SANDY balance:', error);
    return null;
  }
};

export const hasPAWZ = async (owner: string): Promise<boolean> => {
  const response = await fetch(process.env.GATSBY_RPC_ENDPOINT!, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: 'my-id',
      method: 'searchAssets',
      params: {
        ownerAddress: owner,
        compressed: true,
        creatorAddress: 'FdZMi5AoVGAJenN673Fz1vcpWmHDcWiuaThBetKqpgnR',
        page: 1, // Starts at 1
        limit: 1,
      },
    }),
  });
  const { result } = await response.json();

  return result.total > 0;
};
